import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import api from '../api'; // Usar la instancia configurada de Axios

const units = ["unit", "ml", "cc", "minute", "hour"];

const AddProductModal = ({ open, onClose, onProductAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    unitMeasure: '',
    presentation: '',
    sanitaryRegistry: ''
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Limpiar el error cuando el usuario cambia el campo
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required.";
    tempErrors.type = formData.type ? "" : "This field is required.";
    tempErrors.unitMeasure = formData.unitMeasure ? "" : "This field is required.";
    tempErrors.presentation = formData.presentation ? "" : "This field is required.";
    tempErrors.sanitaryRegistry = formData.sanitaryRegistry ? "" : "This field is required.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        const response = await api.post('/products', formData);
        onProductAdded(response.data);
        onClose();
      } catch (error) {
        console.error('Error adding product:', error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Product</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          select
          name="type"
          label="Type"
          value={formData.type}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.type}
          helperText={errors.type}
        >
          <MenuItem value="Consumable">Consumable</MenuItem>
          <MenuItem value="Machine">Machine</MenuItem>
        </TextField>
        <TextField
          select
          name="unitMeasure"
          label="Unit of Measure"
          value={formData.unitMeasure}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.unitMeasure}
          helperText={errors.unitMeasure}
        >
          {units.map((unit) => (
            <MenuItem key={unit} value={unit}>
              {unit}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="presentation"
          label="Presentation"
          type="number"
          value={formData.presentation}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.presentation}
          helperText={errors.presentation}
        />
        <TextField
          name="sanitaryRegistry"
          label="Sanitary Registry"
          value={formData.sanitaryRegistry}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.sanitaryRegistry}
          helperText={errors.sanitaryRegistry}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductModal;
