import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import EventIcon from '@mui/icons-material/Event';
import HealingIcon from '@mui/icons-material/Healing';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';

const actions = [
  { label: 'Payment', icon: PaymentIcon, modal: 'payment' },
  { label: 'Appointment', icon: EventIcon, modal: 'appointment' },
  { label: 'Treatment', icon: HealingIcon, modal: 'treatment' },
  { label: 'Edit', icon: EditIcon, modal: 'edit' },
  { label: 'Consent', icon: DescriptionIcon, modal: 'consent' },
];

const PatientActions = ({ handleOpenModal }) => {
  return (
    <Grid container spacing={2} justifyContent="flex-start">
      {actions.map(({ label, icon: Icon, modal }) => (
        <Grid item key={label}>
          <IconButton
            onClick={() => handleOpenModal(modal)}
            sx={{
              width: 56,
              height: 56,
              bgcolor: 'background.paper',
              boxShadow: 1,
              '&:hover': {
                bgcolor: 'primary.light',
              },
            }}
          >
            <Icon fontSize="large" />
          </IconButton>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default PatientActions;
