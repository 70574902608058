import React, { useEffect, useState } from 'react';
import api from '../api';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
} from '@mui/material';

/**
 * Función utilitaria para formatear la fecha
 * @param {string} dateString - Fecha en formato string
 * @returns {string} - Fecha formateada
 */
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString();
};

/**
 * Función para renderizar el estado del consentimiento
 * @param {object} consent - Objeto de consentimiento
 * @returns {JSX.Element} - Chip que representa el estado de la firma
 */
const renderStatusChip = (consent) => {
  const isSigned = !!consent.signatureUrl;
  const label = isSigned ? 'Signed' : 'Pending';
  const color = isSigned ? 'green' : 'orange';
  return (
    <Chip
      label={label}
      sx={{ borderColor: color, color, fontSize: '14px', padding: '4px 8px' }}
      variant="outlined"
    />
  );
};

const ConsentTable = ({ patientId }) => {
  const [consents, setConsents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchConsents = async () => {
      try {
        const response = await api.get(`/informed-consents`, { params: { patientId } });
        setConsents(response.data);
      } catch (error) {
        console.error('Error fetching informed consents:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConsents();
  }, [patientId]);

  const handleRowClick = async (consent) => {
    try {
      const response = await api.get(`/informed-consents/${consent._id}/presigned-url`);
      const { url } = response.data;
      window.open(url, '_blank'); // Abrir la URL en una nueva pestaña
    } catch (error) {
      console.error('Error getting presigned URL:', error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (consents.length === 0) {
    return (
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          Informed Consents
        </Typography>
        <Typography>No informed consents found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, backgroundColor: 'background.paper', borderRadius: 2, boxShadow: 3, mt: 2, mb: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Informed Consents
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Treatment</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Date Sent</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Signature Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {consents.map((consent) => (
              <TableRow
                key={consent._id}
                onClick={() => handleRowClick(consent)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
              >
                <TableCell>{consent.treatmentName || consent.treatmentId?.name || 'Unknown'}</TableCell>
                <TableCell>{formatDate(consent.createdAt)}</TableCell>
                <TableCell>{renderStatusChip(consent)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ConsentTable;
