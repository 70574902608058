import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import api from '../api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clinic, setClinic] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [whatsappConfig, setWhatsappConfig] = useState(null);  // Nueva variable de estado para WhatsApp
  const [openAIConfig, setOpenAIConfig] = useState(null);  // Nueva variable de estado para OpenAI

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get('/auth/me');
      setUser(response.data.user);
      setClinic(response.data.clinic);
      setWhatsappConfig(response.data.clinic.whatsappConfig);  // Configuración de WhatsApp
      setOpenAIConfig(response.data.clinic.openAIConfig);  // Configuración de OpenAI
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [fetchUserData]);

  const login = async ({ token, clinicId, role }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('clinicId', clinicId);
    localStorage.setItem('role', role);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    await fetchUserData();
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('clinicId');
    localStorage.removeItem('role');
    delete api.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
    setUser(null);
    setClinic(null);
    setWhatsappConfig(null);
    setOpenAIConfig(null);
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      login, 
      logout, 
      clinic, 
      user, 
      whatsappConfig, 
      openAIConfig, 
      loading 
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
