import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../api';
import { Container, Typography, Grid, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PatientActions from '../components/PatientActions';
import PatientDetails from '../components/PatientDetails';
import ClinicalHistoryList from '../components/ClinicalHistoryList';
import PatientPaymentForm from '../components/PatientPaymentForm';
import AppointmentModal from '../components/AppointmentModal';
import EditPatientModal from '../components/EditPatientModal';
import ClinicalHistoryForm from '../components/ClinicalHistoryForm';
import { useAuth } from '../contexts/AuthContext';
import PendingBalanceCard from '../components/PendingBalanceCard';
import ConsentSendDialog from '../components/ConsentSendDialog';
import ConsentTable from '../components/ConsentTable';

const PatientDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clinic } = useAuth();
  const [patient, setPatient] = useState(null);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);
  const [openModal, setOpenModal] = useState('');
  const [histories, setHistories] = useState([]);

  const fetchPatient = useCallback(async () => {
    try {
      const response = await api.get(`/patients/${id}?clinicId=${clinic._id}`);
      setPatient(response.data);
    } catch (error) {
      console.error('Error fetching patient:', error);
    }
  }, [id, clinic]);

  const fetchHistories = useCallback(async () => {
    try {
      const response = await api.get(`/clinicalHistories/patient/${id}?clinicId=${clinic._id}`);
      setHistories(response.data);
    } catch (error) {
      console.error('Error fetching clinical histories:', error);
    }
  }, [id, clinic]);

  const fetchPendingBalance = useCallback(async () => {
    try {
      const response = await api.get(`/clinicalHistories/patient/${id}/pending-balance`);
      setTotalPendingAmount(response.data.totalPendingBalance);
    } catch (error) {
      console.error('Error fetching pending balance:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchPatient();
    fetchHistories();
    fetchPendingBalance();
  }, [fetchPatient, fetchHistories, fetchPendingBalance]);

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  const handleCloseModal = () => {
    setOpenModal('');
  };

  const handlePatientUpdated = (updatedPatient) => {
    setPatient(updatedPatient);
  };

  const handleHistoryAdded = (newHistory) => {
    setHistories((prevHistories) => [...prevHistories, newHistory]);
  };

  const handlePaymentAdded = () => {
    fetchHistories();
    fetchPendingBalance();
    handleCloseModal();
  };

  if (!patient) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Container maxWidth="lg" >
      <Grid container spacing={3}>
        {/* Header de la página */}
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => navigate('/patients')}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h4">{`${patient.firstName} ${patient.lastName}`}</Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Acciones del paciente */}
        <Grid item xs={12}>
          <PatientActions handleOpenModal={handleOpenModal} />
        </Grid>

        {/* Saldo pendiente */}
        <Grid item xs={12}>
          <PendingBalanceCard amount={totalPendingAmount} />
        </Grid>

        {/* Detalles y historial clínico del paciente */}
        <Grid item xs={12} md={6}>
          <PatientDetails patient={patient} />
          <ConsentTable patientId={patient._id} />
        </Grid>

        <Grid item xs={12} md={6}>
          <ClinicalHistoryList patientId={patient._id} histories={histories} />
        </Grid>
      </Grid>

      {/* Modal Management */}
      {openModal === 'payment' && (
        <PatientPaymentForm
          open={true}
          onClose={handleCloseModal}
          onPaymentAdded={handlePaymentAdded}
        />
      )}
      {openModal === 'appointment' && (
        <AppointmentModal
          open={true}
          onClose={handleCloseModal}
          patientId={patient._id}
        />
      )}
      {openModal === 'edit' && (
        <EditPatientModal
          open={true}
          onClose={handleCloseModal}
          patient={patient}
          onPatientUpdated={handlePatientUpdated}
        />
      )}
      {openModal === 'treatment' && (
        <ClinicalHistoryForm
          open={true}
          onClose={handleCloseModal}
          patientId={patient._id}
          onHistoryAdded={handleHistoryAdded}
        />
      )}
      {openModal === 'consent' && (
        <ConsentSendDialog
          open={true}
          onClose={handleCloseModal}
          patientId={patient._id}
        />
      )}
    </Container>
  );
};

export default PatientDetailPage;
