import React, { useState, useEffect } from 'react';
import api from '../api'; // Usar la instancia configurada de Axios
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, MenuItem, Box, Autocomplete } from '@mui/material';
import { useAuth } from '../contexts/AuthContext'; // Importar el contexto de autenticación

const ProviderPaymentForm = ({ open, onClose, onPaymentAdded }) => {
  // Función para obtener la fecha de hoy en formato ISO (YYYY-MM-DD)
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Estado para almacenar los datos del formulario de pago del proveedor
  const [formData, setFormData] = useState({
    provider: '',
    amount: '',
    account: '',
    providerPurchase: '',
    method: '',
    date: getTodayDate()
  });

  // Estado para almacenar la lista de proveedores
  const [providers, setProviders] = useState([]);
  // Estado para almacenar la lista de compras de proveedores
  const [providerPurchases, setProviderPurchases] = useState([]);
  // Estado para almacenar la lista de cuentas
  const [accounts, setAccounts] = useState([]);

  // Obtener la clínica del contexto de autenticación
  const { clinic } = useAuth();

  // useEffect para obtener los proveedores y cuentas al montar el componente
  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await api.get('/providers', {
          params: {
            clinicId: clinic._id // Enviar clinicId como parámetro
          }
        });
        setProviders(response.data);
      } catch (error) {
        console.error('Error fetching providers:', error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await api.get('/accounts', {
          params: {
            clinicId: clinic._id, // Asegúrate de enviar clinicId como parámetro
            codes: ['1101', '1102'] // Filtrar por los códigos de cuenta requeridos
          }
        });
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    if (clinic) {
      fetchProviders();
      fetchAccounts();
    }
  }, [clinic]);

  // Manejar el cambio de proveedor en el formulario
  const handleProviderChange = async (event, newValue) => {
    setFormData({ ...formData, provider: newValue ? newValue._id : '' });

    try {
      const response = await api.get(`/providerpurchases/provider/${newValue ? newValue._id : ''}`, {
        params: {
          clinicId: clinic._id // Enviar clinicId como parámetro
        }
      });
      setProviderPurchases(response.data);
    } catch (error) {
      console.error('Error fetching provider purchases:', error);
    }
  };

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Manejar el envío del formulario de pago
  const handleSubmit = async () => {
    try {
      const response = await api.post('/providerPayments', {
        ...formData,
        clinic: clinic._id // Incluir clinicId en los datos del formulario
      });
      onPaymentAdded(response.data);
      onClose();
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Provider Payment</DialogTitle>
      <DialogContent>
        {/* Campo de selección de proveedor */}
        <Autocomplete
          value={providers.find(provider => provider._id === formData.provider) || null}
          onChange={handleProviderChange}
          options={providers}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Provider" variant="outlined" margin="normal" fullWidth />
          )}
        />
        {/* Campo de cantidad */}
        <TextField
          label="Amount"
          name="amount"
          type="number"
          value={formData.amount}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {/* Campo de cuenta */}
        <Autocomplete
          value={accounts.find(account => account._id === formData.account) || null}
          onChange={(event, newValue) => setFormData({ ...formData, account: newValue ? newValue._id : '' })}
          options={accounts}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Account" variant="outlined" margin="normal" fullWidth />
          )}
        />
        {/* Campo de selección de método de pago */}
        <TextField
          select
          label="Payment Method"
          name="method"
          value={formData.method}
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          <MenuItem value="credit card">Credit Card</MenuItem>
          <MenuItem value="bank transfer">Bank Transfer</MenuItem>
          <MenuItem value="Cash">Cash</MenuItem>
        </TextField>
        {/* Campo de selección de compra del proveedor */}
        <Autocomplete
          value={providerPurchases.find(purchase => purchase._id === formData.providerPurchase) || null}
          onChange={(event, newValue) => setFormData({ ...formData, providerPurchase: newValue ? newValue._id : '' })}
          options={providerPurchases}
          getOptionLabel={(option) => `Purchase on ${new Date(option.date).toLocaleDateString()} - ${option.total}`}
          renderInput={(params) => (
            <TextField {...params} label="Provider Purchase" variant="outlined" margin="normal" fullWidth />
          )}
        />
        {/* Campo de fecha */}
        <TextField
          label="Date"
          name="date"
          type="date"
          value={formData.date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProviderPaymentForm;
