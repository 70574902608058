import React, { useEffect, useState } from 'react';
import api from '../api';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Drawer,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ClinicalHistoryList = ({ patientId }) => {
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHistory, setSelectedHistory] = useState(null);

  useEffect(() => {
    const fetchHistoriesAndPayments = async () => {
      try {
        const [historiesResponse, paymentsResponse] = await Promise.all([
          api.get(`/clinicalHistories/patient/${patientId}?populate=treatmentsPerformed.treatment`),
          api.get('/patientPayments'),
        ]);

        const histories = historiesResponse.data;
        const payments = paymentsResponse.data.filter(payment => payment.patient._id === patientId);

        const historiesWithPayments = histories.map(history => ({
          ...history,
          payments: payments.filter(payment => payment.clinicalHistory._id === history._id),
        }));

        setHistories(historiesWithPayments);
      } catch (error) {
        console.error('Error fetching clinical histories and payments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistoriesAndPayments();
  }, [patientId]);

  const handleRowClick = (history) => {
    setSelectedHistory(history);
  };

  const handleCloseDrawer = () => {
    setSelectedHistory(null);
  };

  const formatCurrency = (amount) => {
    return `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)}`;
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (histories.length === 0) {
    return (
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
          Clinical Histories
        </Typography>
        <Typography>No clinical histories found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, backgroundColor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
        Clinical Histories
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Treatments Performed</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Invoice Amount</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Payments</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {histories.map((history) => (
              <TableRow
                key={history._id}
                onClick={() => handleRowClick(history)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
              >
                <TableCell>
                  {history.treatmentsPerformed.map(treatment => treatment.treatment.name).join(', ')}
                </TableCell>
                <TableCell>{new Date(history.dateTime).toLocaleDateString()}</TableCell>
                <TableCell>{formatCurrency(history.invoiceAmount)}</TableCell>
                <TableCell>{history.payments.length > 0 ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Drawer for displaying clinical history details */}
      <Drawer
        anchor="right"
        open={Boolean(selectedHistory)}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: { width: { xs: '100%', md: '40%' } }, // Responsividad: 100% ancho en móviles, 40% en pantallas grandes
        }}
      >
        {selectedHistory && (
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Clinical History Details</Typography>
              <IconButton onClick={handleCloseDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>
            {[
              { label: 'Reason for Consultation', value: selectedHistory.reasonForConsultation },
              { label: 'Date and Time', value: new Date(selectedHistory.dateTime).toLocaleString() },
              { label: 'Pathological', value: selectedHistory.pathological },
              { label: 'Pharmacological', value: selectedHistory.pharmacological },
              { label: 'Surgical', value: selectedHistory.surgical },
              { label: 'Allergic', value: selectedHistory.allergic },
              { label: 'Toxicological', value: selectedHistory.toxicological },
              { label: 'Weight', value: selectedHistory.weight },
              { label: 'Height', value: selectedHistory.height },
              { label: 'BMI', value: selectedHistory.bmi },
              { label: 'Observations', value: selectedHistory.observations },
              { label: 'Skin Type', value: selectedHistory.skinType },
              { label: 'Fitzpatrick Scale', value: selectedHistory.fitzpatrickScale },
              { label: 'Diagnosis', value: selectedHistory.diagnosis },
              { label: 'Treatments Performed', value: selectedHistory.treatmentsPerformed.map(treatment => treatment.treatment.name).join(', ') },
              { label: 'Consumables Used', value: selectedHistory.consumablesUsed.map(consumable => `${consumable.consumable.name} (Qty: ${consumable.quantity})`).join(', ') },
              { label: 'Treatment Notes', value: selectedHistory.treatmentNotes },
              { label: 'Invoice Amount', value: formatCurrency(selectedHistory.invoiceAmount) },
            ].map((detail, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{detail.label}:</Typography>
                <Typography>{detail.value || 'N/A'}</Typography>
              </Box>
            ))}
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>Payments:</Typography>
            {selectedHistory.payments && selectedHistory.payments.length > 0 ? (
              selectedHistory.payments.map(payment => (
                <Box key={payment._id} sx={{ mb: 2 }}>
                  <Typography variant="body2">Amount: {formatCurrency(payment.amount)}</Typography>
                  <Typography variant="body2">Account: {payment.account ? payment.account.name : 'N/A'}</Typography>
                </Box>
              ))
            ) : (
              <Typography>No payments recorded.</Typography>
            )}
          </Box>
        )}
      </Drawer>
    </Box>
  );
};

export default ClinicalHistoryList;
