import React, { useState, useEffect } from 'react';
import api from '../api';
import {
  Dialog, DialogContent, DialogActions, Button, Stepper, Step, StepLabel, Box, Typography, IconButton, TextField, MenuItem, Switch, FormControlLabel
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';

// Importar los subcomponentes desde /src/components
import MedicalHistorySection from '../components/MedicalHistorySection';
import PhysicalExamSection from '../components/PhysicalExamSection';
import FinancialSection from '../components/FinancialSection';

const ClinicalHistoryForm = ({ open, onClose, patientId, onHistoryAdded }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Medical history', 'Physical exam', 'Treatment', 'Payment'];
  const [showPaymentFields, setShowPaymentFields] = useState(true);

  const [formData, setFormData] = useState({
    reasonForConsultation: '',
    dateTime: new Date().toISOString().substring(0, 16),
    pathological: '',
    pharmacological: '',
    surgical: '',
    allergic: '',
    toxicological: '',
    weight: 0,
    height: 0,
    bmi: 0,
    observations: '',
    skinType: '',
    fitzpatrickScale: 'I',
    diagnosis: '',
    treatmentsPerformed: [],
    consumablesUsed: [],
    treatmentNotes: '',
    invoiceAmount: "0",
    payments: showPaymentFields ? [{ amount: '', account: '' }] : []
  });

  const { clinic } = useAuth();
  const [treatments, setTreatments] = useState([]);
  const [consumables, setConsumables] = useState([]);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const response = await api.get('/treatments');
        setTreatments(response.data);
      } catch (error) {
        console.error('Error fetching treatments:', error);
      }
    };

    const fetchConsumables = async () => {
      try {
        const response = await api.get('/products');
        setConsumables(response.data);
      } catch (error) {
        console.error('Error fetching consumables:', error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await api.get('/accounts', {
          params: {
            codes: ['1101', '1102']
          }
        });
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchTreatments();
    fetchConsumables();
    fetchAccounts();
  }, [clinic]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [section, index, field] = name.split('.');

    setFormData(prevState => {
      if (section === 'payments') {
        const updatedPayments = [...prevState.payments];
        if (!updatedPayments[parseInt(index)]) {
          updatedPayments[parseInt(index)] = { amount: '', account: '' };
        }
        if (field === 'amount') {
          // Se parsea el valor como número
          const numericValue = parseInt(value.replace(/\D/g, ''), 10);
          updatedPayments[parseInt(index)][field] = isNaN(numericValue) ? '' : numericValue;
        } else {
          updatedPayments[parseInt(index)][field] = value;
        }
        return { ...prevState, payments: updatedPayments };
      } else if (section === 'treatmentsPerformed') {
        const updatedTreatments = [...prevState.treatmentsPerformed];
        if (!updatedTreatments[parseInt(index)]) {
          updatedTreatments[parseInt(index)] = { treatment: '', amount: 0 };
        }
        if (field === 'amount') {
          // Se parsea el valor como número
          const numericValue = parseInt(value.replace(/\D/g, ''), 10);
          updatedTreatments[parseInt(index)][field] = isNaN(numericValue) ? '' : numericValue;
        } else {
          updatedTreatments[parseInt(index)][field] = value;
        }
        const invoiceAmount = updatedTreatments.reduce((total, t) => total + (t.amount || 0), 0);
        return { ...prevState, treatmentsPerformed: updatedTreatments, invoiceAmount };
      } else if (section === 'consumablesUsed') {
        const updatedConsumables = [...prevState.consumablesUsed];
        if (!updatedConsumables[parseInt(index)]) {
          updatedConsumables[parseInt(index)] = { consumable: '', quantity: 0 };
        }
        if (field === 'quantity') {
          const numericValue = parseInt(value.replace(/\D/g, ''), 10);
          updatedConsumables[parseInt(index)][field] = isNaN(numericValue) ? '' : numericValue;
        } else {
          updatedConsumables[parseInt(index)][field] = value;
        }
        return { ...prevState, consumablesUsed: updatedConsumables };
      } else if (name === 'weight' || name === 'height') {
        const updatedData = { ...prevState, [name]: value };
        if (name === 'weight' || name === 'height') {
          const weight = name === 'weight' ? value : prevState.weight;
          const height = name === 'height' ? value : prevState.height;
          const bmi = weight && height ? (weight / ((height / 100) ** 2)).toFixed(2) : 0;
          updatedData.bmi = bmi;
        }
        return updatedData;
      } else {
        return {
          ...prevState,
          [name]: value
        };
      }
    });
  };

  const handleFocus = (e) => {
    e.target.value = '';
  };

  const formatCurrency = (value) => {
    if (!value || isNaN(value)) return ''; // Se evita formatear si no es un número válido
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
  };

  const handleDeleteTreatment = (index) => {
    setFormData(prevState => {
      const updatedTreatments = [...prevState.treatmentsPerformed];
      updatedTreatments.splice(index, 1);
      return { ...prevState, treatmentsPerformed: updatedTreatments };
    });
  };

  const handleDeleteConsumable = (index) => {
    setFormData(prevState => {
      const updatedConsumables = [...prevState.consumablesUsed];
      updatedConsumables.splice(index, 1);
      return { ...prevState, consumablesUsed: updatedConsumables };
    });
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const formattedData = {
        ...formData,
        treatmentsPerformed: formData.treatmentsPerformed.map(t => ({
          ...t,
          amount: t.amount // Se envía como número
        })),
        consumablesUsed: formData.consumablesUsed.map(c => ({
          ...c,
          quantity: c.quantity // Se envía como número
        })),
        patient: patientId,
        clinic: clinic._id,
        payments: []
      };

      const historyResponse = await api.post('/clinicalHistories', formattedData);

      const createdHistory = historyResponse.data;

      if (showPaymentFields && formData.payments.length > 0) {
        const paymentPromises = formData.payments.map(payment => {
          return api.post('/patientPayments', {
            ...payment,
            patient: patientId,
            clinicalHistory: createdHistory._id,
            clinic: clinic._id
          });
        });

        await Promise.all(paymentPromises);
      }

      onHistoryAdded(createdHistory);
      onClose();
    } catch (error) {
      console.error('Error adding clinical history:', error);
    }
  };

  const addTreatment = () => {
    setFormData({
      ...formData,
      treatmentsPerformed: [...formData.treatmentsPerformed, { treatment: '', amount: '' }]
    });
  };

  const addConsumable = () => {
    setFormData({
      ...formData,
      consumablesUsed: [...formData.consumablesUsed, { consumable: '', quantity: '' }]
    });
  };

  const toggleShowPaymentFields = () => {
    setShowPaymentFields(!showPaymentFields);
  };

  const StepContent = ({ step }) => {
    switch (step) {
      case 0:
        return (
          <>
            <MedicalHistorySection formData={formData} handleChange={handleChange} />
          </>
        );
      case 1:
        return (
          <>
            <PhysicalExamSection formData={formData} handleChange={handleChange} />
          </>
        );
      case 2:
        return (
          <>
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Treatments performed
              </Typography>
              {formData.treatmentsPerformed.length === 0 ? (
                <Typography variant="body2" gutterBottom>
                  No treatments added yet. Click '+ ADD TREATMENT' to begin.
                </Typography>
              ) : (
                formData.treatmentsPerformed.map((treatment, index) => (
                  <Box key={index} sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <TextField
                      select
                      label="Treatment"
                      name={`treatmentsPerformed.${index}.treatment`}
                      value={treatment.treatment}
                      onChange={handleChange}
                      sx={{ minWidth: 200 }}
                    >
                      {treatments.map((t) => (
                        <MenuItem key={t._id} value={t._id}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Amount ($)"
                      name={`treatmentsPerformed.${index}.amount`}
                      value={treatment.amount === '' ? '' : formatCurrency(treatment.amount)}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      sx={{ minWidth: 150 }}
                    />
                    <IconButton onClick={() => handleDeleteTreatment(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              )}
              <Button variant="outlined" onClick={addTreatment} sx={{ mt: 3 }}>
                + ADD TREATMENT
              </Button>
            </Box>
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Consumables used
              </Typography>
              {formData.consumablesUsed.length === 0 ? (
                <Typography variant="body2" gutterBottom>
                  No consumables added yet. Click '+ ADD CONSUMABLE' to begin.
                </Typography>
              ) : (
                formData.consumablesUsed.map((consumable, index) => (
                  <Box key={index} sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
                    <TextField
                      select
                      label="Consumable"
                      name={`consumablesUsed.${index}.consumable`}
                      value={consumable.consumable}
                      onChange={handleChange}
                      sx={{ minWidth: 200 }}
                    >
                      {consumables.map((c) => (
                        <MenuItem key={c._id} value={c._id}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Quantity"
                      name={`consumablesUsed.${index}.quantity`}
                      value={consumable.quantity === '' ? '' : consumable.quantity}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      sx={{ minWidth: 150 }}
                    />
                    <IconButton onClick={() => handleDeleteConsumable(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              )}
              <Button variant="outlined" onClick={addConsumable} sx={{ mt: 3 }}>
                + ADD CONSUMABLE
              </Button>
            </Box>
          </>
        );
      case 3:
        return (
          <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2, textAlign: 'center' }}>
              Financial Summary
            </Typography>
            {formData.treatmentsPerformed.length > 0 && formData.treatmentsPerformed.map((treatment, index) => {
              const treatmentName = treatments.find(t => t._id === treatment.treatment)?.name || 'N/A';
              return (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', width: '60%', mb: 1 }}>
                  <Typography variant="body1">{`Treatment ${index + 1}: ${treatmentName}`}</Typography>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{formatCurrency(treatment.amount)}</Typography>
                </Box>
              );
            })}
            <Typography variant="body1" sx={{ mt: 2, fontWeight: 'bold', textAlign: 'center', width: '60%' }}>
              {`Invoice Amount: ${formatCurrency(formData.invoiceAmount)}`}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={showPaymentFields}
                  onChange={toggleShowPaymentFields}
                  color="primary"
                />
              }
              label="Register Payment"
              sx={{ mt: 3 }}
            />
            {showPaymentFields && (
              <Box sx={{ mt: 2, width: '60%', display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                  label="Amount ($)"
                  name={`payments.0.amount`}
                  value={formData.payments[0]?.amount === '' ? '' : formatCurrency(formData.payments[0]?.amount)}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  sx={{ width: '48%' }}
                />
                <TextField
                  select
                  label="Account"
                  name={`payments.0.account`}
                  value={formData.payments[0]?.account || ''}
                  onChange={handleChange}
                  sx={{ width: '48%' }}
                >
                  {accounts.map((account) => (
                    <MenuItem key={account._id} value={account._id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogContent>
        <Box sx={{ mb: 2, mt: 2 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {StepContent({ step: activeStep })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {activeStep !== 0 && (
          <Button onClick={handleBack}>
            Back
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        ) : (
          <Button onClick={handleNext} color="primary">
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ClinicalHistoryForm;
