import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Link, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(''); // Para manejar y mostrar errores

  /**
   * Handles form submission for login.
   * Sends login credentials to the server and processes the response.
   *
   * @param {object} event - The form submission event.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Limpiar cualquier error previo
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        throw new Error('Login failed');
      }
      const data = await response.json();
      const { token, clinicId, role } = data;
      await login({ token, clinicId, role });
      navigate('/patients');  // Navegar a la página de pacientes después del inicio de sesión exitoso
    } catch (error) {
      console.error('Error during login:', error);
      setError('Login failed. Please check your credentials and try again.'); // Mensaje de error amigable
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Aseguramos que el contenedor ocupe toda la altura de la pantalla
        backgroundColor: 'background.default', // Opcional: Agregar un color de fondo, si lo deseas
      }}
    >
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Login
        </Typography>
        {error && (
          <Typography color="error" sx={{ mb: 2 }} align="center">
            {error}
          </Typography>
        )}
        <form onSubmit={handleSubmit} method="POST">
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Typography>
            Don't have an account? <Link href="/signup">Signup</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
