import React, { useState, useEffect } from 'react';
import api from '../api';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, MenuItem, Grid, Box, Typography } from '@mui/material';
import ClinicalHistoryForm from './ClinicalHistoryForm';
import { useAuth } from '../contexts/AuthContext';

const PatientPaymentForm = ({ open, onClose, onPaymentAdded }) => {
  const [formData, setFormData] = useState({
    patient: '',
    amount: '',
    account: '',
    clinicalHistory: ''
  });

  const [patients, setPatients] = useState([]);
  const [clinicalHistories, setClinicalHistories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [openClinicalHistoryForm, setOpenClinicalHistoryForm] = useState(false);
  const { clinic } = useAuth();

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get('/patients', {
          params: { clinicId: clinic._id }
        });
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const response = await api.get('/accounts', {
          params: { codes: ['1101', '1102'] }
        });
        setAccounts(response.data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    if (clinic) {
      fetchPatients();
      fetchAccounts();
    }
  }, [clinic]);

  const formatCurrency = (amount) => {
    return `$${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)}`;
  };

  const handlePatientChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    try {
      const response = await api.get(`/clinicalHistories/patient/${value}/pending-payments`, {
        params: { clinicId: clinic._id }
      });

      const formattedHistories = response.data.map(history => {
        const totalPaid = history.totalPaid || 0;
        const pendingAmount = history.invoiceAmount - totalPaid;

        const treatmentNames = history.treatmentsPerformed.map(treatment => treatment.treatment.name).join(', ');
        return {
          ...history,
          formattedLabel: `${treatmentNames} - ${formatCurrency(pendingAmount)}`,
          pendingAmount
        };
      });

      setClinicalHistories(formattedHistories);
    } catch (error) {
      console.error('Error fetching clinical histories:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'clinicalHistory' && value === 'addNew') {
      setOpenClinicalHistoryForm(true);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post('/patientPayments', {
        ...formData,
        clinic: clinic._id,
        amount: parseFloat(formData.amount.replace(/[^\d]/g, ''))
      });
      onPaymentAdded(response.data);
      onClose();
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  const handleHistoryAdded = async (newHistory) => {
    setClinicalHistories([...clinicalHistories, newHistory]);
    setFormData({ ...formData, clinicalHistory: newHistory._id });
    setOpenClinicalHistoryForm(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add Patient Payment</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Patient"
                  name="patient"
                  value={formData.patient}
                  onChange={handlePatientChange}
                  fullWidth
                >
                  {patients.map((patient) => (
                    <MenuItem key={patient._id} value={patient._id}>
                      {`${patient.firstName} ${patient.lastName}`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Clinical History"
                  name="clinicalHistory"
                  value={formData.clinicalHistory}
                  onChange={handleChange}
                  fullWidth
                >
                  {clinicalHistories.map((history) => (
                    <MenuItem key={history._id} value={history._id}>
                      {history.formattedLabel}
                    </MenuItem>
                  ))}
                  <MenuItem value="addNew">
                    <em>Add New Clinical History</em>
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Account"
                  name="account"
                  value={formData.account}
                  onChange={handleChange}
                  fullWidth
                >
                  {accounts.map((account) => (
                    <MenuItem key={account._id} value={account._id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">Submit</Button>
        </DialogActions>
      </Dialog>

      <ClinicalHistoryForm
        open={openClinicalHistoryForm}
        onClose={() => setOpenClinicalHistoryForm(false)}
        patientId={formData.patient}
        onHistoryAdded={handleHistoryAdded}
        showPaymentFields={false}
      />
    </>
  );
};

export default PatientPaymentForm;
