// Import necessary libraries and components
import React, { useState } from 'react';
import { Box, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import api from '../api'; // Axios instance for API calls
import { useAuth } from '../contexts/AuthContext'; // Authentication context to get clinic ID

const CompartmentCrud = ({ compartments, fetchCompartments }) => {
  const { clinic } = useAuth(); // Get the clinic ID from the authentication context
  const [newCompartmentName, setNewCompartmentName] = useState(''); // State for new compartment name
  const [loading, setLoading] = useState(false); // Loading state

  // Handle the creation of a new compartment
  const handleCreateCompartment = async () => {
    if (!newCompartmentName) return; // Do nothing if the name is empty
    setLoading(true);
    try {
      await api.post('/compartments', {
        name: newCompartmentName,
        clinic: clinic._id,
      });
      setNewCompartmentName(''); // Reset the input field
      fetchCompartments(); // Refresh compartments list
    } catch (error) {
      console.error('Error creating compartment:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle the deletion of a compartment
  const handleDeleteCompartment = async (compartmentId) => {
    setLoading(true);
    try {
      await api.delete(`/compartments/${compartmentId}`);
      fetchCompartments(); // Refresh compartments list
    } catch (error) {
      console.error('Error deleting compartment:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* List of existing compartments */}
      <List sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {compartments.map((compartment) => (
          <ListItem key={compartment._id}>
            <ListItemText primary={compartment.name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => handleDeleteCompartment(compartment._id)}
                disabled={loading}
                size="small" // Tamaño del botón pequeño
              >
                <DeleteIcon fontSize="small" /> {/* Tamaño del ícono pequeño */}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      {/* Add new compartment input */}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <TextField
          label="Add new compartment"
          value={newCompartmentName}
          onChange={(e) => setNewCompartmentName(e.target.value)}
          variant="outlined"
          fullWidth
          size="small" // Campo ajustado a small
        />
        <IconButton
          color="primary"
          onClick={handleCreateCompartment}
          disabled={loading}
          sx={{ 
            //border: '1px solid', // Borde alrededor del icono +
            //borderRadius: 2, // Hacer el borde redondo
            ml: 1, // Añadir un margen izquierdo
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CompartmentCrud;
