// Import necessary libraries and components
import React, { useEffect, useState } from 'react';
import { Box, TextField, Autocomplete, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import api from '../api'; // Axios instance for API calls
import { useAuth } from '../contexts/AuthContext'; // Authentication context to get clinic ID

const EnvironmentalConditionTable = ({ compartments }) => {
  const { clinic } = useAuth(); // Get the clinic ID from the authentication context
  const [conditions, setConditions] = useState([]); // State for storing environmental conditions
  const [loading, setLoading] = useState(false); // Loading state
  const [newCondition, setNewCondition] = useState({
    temperature: '',
    humidity: '',
    compartment: null,
  });

  // Fetch all environmental conditions for the clinic
  const fetchConditions = async () => {
    setLoading(true); // Set loading state
    try {
      const response = await api.get(`/environmental-conditions/clinic/${clinic._id}`);
      const sortedConditions = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setConditions(sortedConditions); // Set the fetched conditions in state, ordered by date
    } catch (error) {
      console.error('Error fetching conditions:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // Fetch conditions when the component is mounted or clinic changes
  useEffect(() => {
    if (clinic) {
      fetchConditions(); // Fetch conditions when clinic is available
    }
  }, [clinic]);

  // Handle adding a new condition
  const handleAddCondition = async () => {
    if (!newCondition.compartment || !newCondition.temperature || !newCondition.humidity) {
      return; // Validate that the form fields are filled
    }

    try {
      // API call to add a new condition
      await api.post('/environmental-conditions', {
        temperature: newCondition.temperature,
        humidity: newCondition.humidity,
        compartment: newCondition.compartment._id, // Pass the compartment ID
        clinic: clinic._id, // Pass the clinic ID
      });
      fetchConditions(); // Refresh the table with new condition
      setNewCondition({ temperature: '', humidity: '', compartment: null }); // Reset form fields
    } catch (error) {
      console.error('Error adding condition:', error);
    }
  };

  // Handle deleting a condition
  const handleDeleteCondition = async (conditionId) => {
    try {
      await api.delete(`/environmental-conditions/${conditionId}`); // API call to delete condition
      fetchConditions(); // Refresh the table after deletion
    } catch (error) {
      console.error('Error deleting condition:', error);
    }
  };

  return (
    <Box>
      {/* Title with margin */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Temperature and Humidity Log
      </Typography>

      {/* Input fields for new condition with size="small" */}
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Autocomplete
          options={compartments}
          getOptionLabel={(option) => option.name} // Display compartment name
          value={newCondition.compartment}
          onChange={(e, value) => setNewCondition({ ...newCondition, compartment: value })}
          renderInput={(params) => <TextField {...params} label="Compartment" size="small" />}
          fullWidth
        />
        <TextField
          label="Temperature (°C)"
          type="number"
          value={newCondition.temperature}
          onChange={(e) => setNewCondition({ ...newCondition, temperature: e.target.value })}
          fullWidth
          size="small"
        />
        <TextField
          label="Humidity (%)"
          type="number"
          value={newCondition.humidity}
          onChange={(e) => setNewCondition({ ...newCondition, humidity: e.target.value })}
          fullWidth
          size="small"
        />
        <IconButton color="primary" onClick={handleAddCondition}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Table to display conditions */}
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="environmental conditions table">
          <TableHead>
            <TableRow>
              <TableCell>Temperature (°C)</TableCell>
              <TableCell>Humidity (%)</TableCell>
              <TableCell>Compartment</TableCell>
              <TableCell align="center">Actions</TableCell> {/* New column for delete icon */}
            </TableRow>
          </TableHead>
          <TableBody>
            {conditions.map((condition) => (
              <TableRow key={condition._id}>
                <TableCell>{condition.temperature}</TableCell>
                <TableCell>{condition.humidity}</TableCell>
                <TableCell>
                  {condition.compartment && condition.compartment.name
                    ? condition.compartment.name
                    : 'N/A'}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small" // Small icon size
                    
                    onClick={() => handleDeleteCondition(condition._id)}
                  >
                    <DeleteIcon fontSize="small" /> {/* Small delete icon */}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EnvironmentalConditionTable;
