import React, { useState } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import AddProviderPurchaseModal from '../components/AddProviderPurchaseModal';
import ProviderPurchasesTable from '../components/ProviderPurchasesTable';
import { styled } from '@mui/system';
import { useAuth } from '../contexts/AuthContext'; // Import authentication context

// Styled Container for responsive design and spacing adjustments
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  overflow: 'hidden',
}));

const ProviderPurchasesPage = () => {
  // State to control the visibility of the AddProviderPurchaseModal
  const [isAddPurchaseModalOpen, setAddPurchaseModalOpen] = useState(false);
  // State to trigger reload of the purchases table
  const [reloadKey, setReloadKey] = useState(0);
  // Extract clinic from authentication context
  const { clinic } = useAuth();

  // Handler to open the AddProviderPurchaseModal
  const handleAddPurchaseClick = () => {
    setAddPurchaseModalOpen(true);
  };

  // Handler to close the AddProviderPurchaseModal
  const handleAddPurchaseClose = () => {
    setAddPurchaseModalOpen(false);
  };

  // Handler to trigger table reload after a purchase is added
  const handlePurchaseAdded = () => {
    setReloadKey(prevKey => prevKey + 1);
    setAddPurchaseModalOpen(false);
  };

  // Handler to trigger table reload after a purchase is deleted
  const handlePurchaseDeleted = () => {
    setReloadKey(prevKey => prevKey + 1);
  };

  return (
    <StyledContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2">Provider Purchases</Typography>
        <Button variant="contained" color="primary" onClick={handleAddPurchaseClick}>
          Add Purchase
        </Button>
      </Box>
      {clinic && (
        <ProviderPurchasesTable key={reloadKey} onPurchaseDeleted={handlePurchaseDeleted} clinicId={clinic._id} />
      )}
      <AddProviderPurchaseModal
        open={isAddPurchaseModalOpen}
        onClose={handleAddPurchaseClose}
        onPurchaseAdded={handlePurchaseAdded}
        clinicId={clinic ? clinic._id : null}
      />
    </StyledContainer>
  );
};

export default ProviderPurchasesPage;
