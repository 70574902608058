import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Typography, Box, Autocomplete
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import api from '../api'; // Usar la instancia configurada de Axios
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

// Función para obtener la fecha de hoy en formato ISO (YYYY-MM-DD)
const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const AddProviderPurchaseModal = ({ open, onClose, onPurchaseAdded, clinicId }) => {
  const [providers, setProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    provider: '',
    date: getTodayDate(), // Preseleccionar la fecha de hoy
    products: [{ product: '', quantity: '', unitPrice: '', batch: '', expirationDate: '' }],
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      const response = await api.get('/providers');
      setProviders(response.data);
    };

    const fetchProducts = async () => {
      const response = await api.get('/products');
      setProducts(response.data);
    };

    fetchProviders();
    fetchProducts();
  }, []);

  const handleProviderChange = async (event, newValue) => {
    if (typeof newValue === 'string') {
      setFormData({ ...formData, provider: newValue });
    } else if (newValue && newValue.inputValue) {
      setFormData({ ...formData, provider: newValue.inputValue });
    } else {
      setFormData({ ...formData, provider: newValue ? newValue._id : '' });
    }
  };

  const handleProductChange = (index, e) => {
    const { name, value } = e.target;
    const updatedProducts = [...formData.products];
    updatedProducts[index][name] = value;
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleAddProduct = () => {
    setFormData({
      ...formData,
      products: [...formData.products, { product: '', quantity: '', unitPrice: '', batch: '', expirationDate: '' }],
    });
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = [...formData.products];
    updatedProducts.splice(index, 1);
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleSubmit = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    const total = formData.products.reduce((acc, product) => acc + (product.quantity * product.unitPrice), 0);
    const purchaseData = { ...formData, total, clinic: clinicId }; // Agregar el campo clinic

    try {
      const response = await api.post('/providerpurchases', purchaseData);
      onPurchaseAdded(response.data);
      onClose();
      setFormData({
        provider: '',
        date: getTodayDate(), // Reset the date to today
        products: [{ product: '', quantity: '', unitPrice: '', batch: '', expirationDate: '' }],
      }); // Clear form data after successful submission
    } catch (error) {
      console.error('Error adding purchase:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add Provider Purchase</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Autocomplete
              value={providers.find(provider => provider._id === formData.provider) || null}
              onChange={handleProviderChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={providers}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              renderOption={(props, option) => (
                // Corregido: Pasando key directamente en lugar de dentro de ...props
                <li key={option._id} {...props}>{option.name}</li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Provider" variant="outlined" margin="normal" fullWidth />
              )}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              label="Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={(e) => setFormData({ ...formData, date: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Box>
        <Typography variant="h6" gutterBottom>
          Products
        </Typography>
        {formData.products.map((product, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Autocomplete
                value={products.find(prod => prod._id === product.product) || null}
                onChange={(e, newValue) => {
                  const updatedProducts = [...formData.products];
                  updatedProducts[index].product = newValue ? newValue._id : '';
                  setFormData({ ...formData, products: updatedProducts });
                }}
                options={products}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Product" variant="outlined" fullWidth margin="normal" />
                )}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Quantity"
                name="quantity"
                type="number"
                value={product.quantity}
                onChange={(e) => handleProductChange(index, e)}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Unit Price"
                name="unitPrice"
                type="number"
                value={product.unitPrice}
                onChange={(e) => handleProductChange(index, e)}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Batch"
                name="batch"
                value={product.batch}
                onChange={(e) => handleProductChange(index, e)}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Expiration Date"
                name="expirationDate"
                type="date"
                value={product.expirationDate}
                onChange={(e) => handleProductChange(index, e)}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => handleRemoveProduct(index)} color="secondary">
                <RemoveIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        <Button onClick={handleAddProduct} color="primary" startIcon={<AddIcon />}>
          Add Product
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProviderPurchaseModal;
