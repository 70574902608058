import React, { useState, useEffect } from 'react';
import api from '../api'; // Usar la instancia configurada de Axios
import { Container, Typography, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddProductModal from '../components/AddProductModal';
import { useAuth } from '../contexts/AuthContext'; // Importar el contexto de autenticación

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
  const { clinic } = useAuth(); // Obtener clinicId del contexto de autenticación

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await api.get(`/products?clinicId=${clinic._id}`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    if (clinic) {
      fetchProducts();
    }
  }, [clinic]);

  const handleAddProductClick = () => {
    setAddProductModalOpen(true);
  };

  const handleAddProductClose = () => {
    setAddProductModalOpen(false);
  };

  const handleProductAdded = async () => {
    try {
      const response = await api.get(`/products?clinicId=${clinic._id}`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
    setAddProductModalOpen(false);
  };

  const handleDeleteProduct = async (id) => {
    try {
      await api.delete(`/products/${id}?clinicId=${clinic._id}`);
      setProducts(products.filter((product) => product._id !== id));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2">Products</Typography>
        <Button variant="contained" color="primary" onClick={handleAddProductClick}>
          Add Product
        </Button>
      </Box>
      <Table sx={{ '& tbody tr:hover': { backgroundColor: '#f5f5f5' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Unit of Measure</TableCell>
            <TableCell>Presentation</TableCell>
            <TableCell>Sanitary Registry</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product._id}>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.type}</TableCell>
              <TableCell>{product.unitMeasure}</TableCell>
              <TableCell>{product.presentation}</TableCell>
              <TableCell>{product.sanitaryRegistry}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleDeleteProduct(product._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <AddProductModal
        open={isAddProductModalOpen}
        onClose={handleAddProductClose}
        onProductAdded={handleProductAdded}
      />
    </Container>
  );
};

export default ProductsPage;
