import React, { useEffect, useState } from 'react';
import api from '../api'; // Usar la instancia configurada de Axios
import {
  Container, TableContainer, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button, Box, IconButton, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, List, ListItem, ListItemText, Chip, Divider, Grid
} from '@mui/material';
import AddProviderModal from '../components/AddProviderModal';
import ProviderPaymentForm from '../components/ProviderPaymentForm'; // Importar el componente ProviderPaymentForm
import AddProviderPurchaseModal from '../components/AddProviderPurchaseModal'; // Importar el componente AddProviderPurchaseModal
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentIcon from '@mui/icons-material/Payment';
import { useAuth } from '../contexts/AuthContext'; // Importar el contexto de autenticación

function ProvidersPage() {
  const [providers, setProviders] = useState([]);
  const [isAddProviderDialogOpen, setAddProviderDialogOpen] = useState(false);
  const [isProviderPaymentFormOpen, setProviderPaymentFormOpen] = useState(false);
  const [isAddProviderPurchaseModalOpen, setAddProviderPurchaseModalOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerPurchases, setProviderPurchases] = useState([]);
  const [isProviderPurchasesDrawerOpen, setProviderPurchasesDrawerOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [isPurchaseDetailsDrawerOpen, setPurchaseDetailsDrawerOpen] = useState(false);
  const [selectedPurchasePayments, setSelectedPurchasePayments] = useState([]);

  const { clinic } = useAuth();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await api.get(`/providers?clinicId=${clinic._id}`);
        setProviders(response.data);
      } catch (error) {
        console.error('Error fetching providers:', error);
      }
    };

    if (clinic) {
      fetchProviders();
    }
  }, [clinic]);

  const handleAddProviderClick = () => {
    setAddProviderDialogOpen(true);
  };

  const handleAddProviderClose = () => {
    setAddProviderDialogOpen(false);
  };

  const handleProviderAdded = (newProvider) => {
    setProviders([...providers, newProvider]);
    showSnackbar('Provider added successfully!', 'success');
  };

  const handleDeleteProvider = async () => {
    try {
      await api.delete(`/providers/${providerToDelete}?clinicId=${clinic._id}`);
      setProviders(providers.filter((provider) => provider._id !== providerToDelete));
      showSnackbar('Provider deleted successfully!', 'success');
      setConfirmDialogOpen(false);
      setProviderToDelete(null);
    } catch (error) {
      console.error('Error deleting provider:', error);
      showSnackbar('Error deleting provider!', 'error');
      setConfirmDialogOpen(false);
      setProviderToDelete(null);
    }
  };

  const handleDeleteClick = (id) => {
    setProviderToDelete(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setProviderToDelete(null);
  };

  const handleAddProviderPaymentClick = () => {
    setProviderPaymentFormOpen(true);
  };

  const handleProviderPaymentFormClose = () => {
    setProviderPaymentFormOpen(false);
  };

  const handleProviderPaymentAdded = (newPayment) => {
    console.log('Payment added:', newPayment);
    setProviderPaymentFormOpen(false);
    showSnackbar('Provider payment added successfully!', 'success');
  };

  const handleAddProviderPurchaseClick = () => {
    setAddProviderPurchaseModalOpen(true);
  };

  const handleAddProviderPurchaseModalClose = () => {
    setAddProviderPurchaseModalOpen(false);
  };

  const handleProviderPurchaseAdded = (newPurchase) => {
    console.log('Purchase added:', newPurchase);
    setAddProviderPurchaseModalOpen(false);
    showSnackbar('Provider purchase added successfully!', 'success');
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleProviderClick = async (provider) => {
    setSelectedProvider(provider);
    setProviderPurchasesDrawerOpen(true);
    try {
      const response = await api.get(`/providerpurchases/provider/${provider._id}`, {
        params: {
          clinicId: clinic._id,
        },
      });
      const sortedPurchases = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setProviderPurchases(sortedPurchases);
    } catch (error) {
      console.error('Error fetching provider purchases:', error);
    }
  };

  const handlePurchaseClick = async (purchase) => {
    setSelectedPurchase(purchase);
    setPurchaseDetailsDrawerOpen(true);
    try {
      const response = await api.get(`/providerpurchases/${purchase._id}`, {
        params: {
          clinicId: clinic._id,
        },
      });
      setSelectedPurchase(response.data);
      setSelectedPurchasePayments(response.data.payments);
    } catch (error) {
      console.error('Error fetching purchase details:', error);
    }
  };

  const handleProviderPurchasesDrawerClose = () => {
    setProviderPurchasesDrawerOpen(false);
    setSelectedProvider(null);
    setProviderPurchases([]);
  };

  const handlePurchaseDetailsDrawerClose = () => {
    setPurchaseDetailsDrawerOpen(false);
    setSelectedPurchase(null);
    setSelectedPurchasePayments([]);
  };

  const formatCurrency = (value) => {
    return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 2 }}>Providers</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProviderClick}
            startIcon={<AddIcon />}
          >
            Add Provider
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProviderPaymentClick}
            startIcon={<PaymentIcon />}
          >
            Add Provider Payment
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProviderPurchaseClick}
            startIcon={<ShoppingCartIcon />}
          >
            Add Provider Purchase
          </Button>
        </Grid>
      </Grid>

      <TableContainer
  sx={{
    mt: 2,
    border: '1px solid rgba(224, 224, 224, 1)', // Establecer el borde de 4px
    borderRadius: 4, // Aplicar el borde redondeado
    overflow: 'auto', // Asegura que las esquinas redondeadas funcionen
  }}
>
      <Table
        sx={{
          borderCollapse: 'separate', // Necesario para que las esquinas redondeadas funcionen correctamente
          borderSpacing: 0, // Asegura que no haya separación entre celdas
        }}
        aria-label="providers table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Provider Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Address</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {providers.map((provider) => (
            <TableRow
              key={provider._id}
              sx={{
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  cursor: 'pointer',
                },
              }}
              onClick={() => handleProviderClick(provider)}
            >
              <TableCell>{provider.name}</TableCell>
              <TableCell>{provider.email}</TableCell>
              <TableCell>{provider.phone}</TableCell>
              <TableCell>{provider.address}</TableCell>
              <TableCell>
                <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteClick(provider._id); }}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


      <AddProviderModal open={isAddProviderDialogOpen} onClose={handleAddProviderClose} onProviderAdded={handleProviderAdded} />
      <ProviderPaymentForm open={isProviderPaymentFormOpen} onClose={handleProviderPaymentFormClose} onPaymentAdded={handleProviderPaymentAdded} />
      <AddProviderPurchaseModal open={isAddProviderPurchaseModalOpen} onClose={handleAddProviderPurchaseModalClose} onPurchaseAdded={handleProviderPurchaseAdded} clinicId={clinic._id} />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={isConfirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this provider? This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleDeleteProvider} color="primary" autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Drawer anchor="right" open={isProviderPurchasesDrawerOpen} onClose={handleProviderPurchasesDrawerClose}>
        <Box sx={{ width: 400, p: 2 }}>
          <Typography variant="h6">Provider Purchases</Typography>
          <List>
            {providerPurchases.map((purchase) => (
              <ListItem button key={purchase._id} onClick={() => handlePurchaseClick(purchase)}>
                <ListItemText primary={`Purchase on ${new Date(purchase.date).toLocaleDateString()}`} secondary={`Total: ${formatCurrency(purchase.total)}`} />
                {purchase.totalPayments === purchase.total ? (
                  <Chip label="Paid" color="success" variant="outlined" />
                ) : purchase.totalPayments > 0 ? (
                  <Chip label={`Partial: ${formatCurrency(purchase.totalPayments)}`} color="warning" variant="outlined" />
                ) : (
                  <Chip label="Pending" color="default" variant="outlined" />
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Drawer anchor="right" open={isPurchaseDetailsDrawerOpen} onClose={handlePurchaseDetailsDrawerClose}>
        <Box sx={{ width: 400, p: 2 }}>
          <Typography variant="h6">Purchase Details</Typography>
          {selectedPurchase && (
            <Box>
              <Typography variant="body1">Date: {new Date(selectedPurchase.date).toLocaleDateString()}</Typography>
              <Typography variant="body1">Total: {formatCurrency(selectedPurchase.total)}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2">Products:</Typography>
              <List>
                {selectedPurchase.products.map((product) => (
                  <ListItem key={product._id}>
                    <ListItemText primary={`${product.product.name} - ${product.quantity} units`} secondary={`Unit Price: ${formatCurrency(product.unitPrice)} - Batch: ${product.batch}`} />
                  </ListItem>
                ))}
              </List>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2">Payments:</Typography>
              <List>
                {selectedPurchasePayments.map((payment) => (
                  <ListItem key={payment._id}>
                    <ListItemText primary={`Payment on ${new Date(payment.date).toLocaleDateString()}`} secondary={`Amount: ${formatCurrency(payment.amount)} - Method: ${payment.method}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </Drawer>
    </Container>
  );
}

export default ProvidersPage;
