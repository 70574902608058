import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Button, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Delete as DeleteIcon } from '@mui/icons-material';
import AddTreatment from '../components/AddTreatment';
import { useAuth } from '../contexts/AuthContext';
import api from '../api'; // Importar la instancia configurada de Axios

// Define StyledTableRow to highlight the row when hovered
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
}));

function TreatmentsPage() {
  const { clinic } = useAuth();
  const [treatments, setTreatments] = useState([]);
  const [isAddTreatmentDialogOpen, setAddTreatmentDialogOpen] = useState(false);

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const response = await api.get(`/treatments?clinicId=${clinic._id}`);
        setTreatments(response.data);
      } catch (error) {
        console.error('Error fetching treatments:', error);
      }
    };

    if (clinic) {
      fetchTreatments();
    }
  }, [clinic]);

  const handleAddTreatmentClick = () => {
    setAddTreatmentDialogOpen(true);
  };

  const handleAddTreatmentClose = () => {
    setAddTreatmentDialogOpen(false);
  };

  const handleTreatmentAdded = (newTreatment) => {
    setTreatments([...treatments, newTreatment]);
  };

  const handleDeleteTreatment = async (id) => {
    try {
      await api.delete(`/treatments/${id}?clinicId=${clinic._id}`);
      setTreatments(treatments.filter((treatment) => treatment._id !== id));
    } catch (error) {
      console.error('Error deleting treatment:', error);
    }
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h2">Treatments</Typography>
        <Button variant="contained" color="primary" onClick={handleAddTreatmentClick}>
          Add Treatment
        </Button>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Treatment Name</TableCell>
            <TableCell>Duration (minutes)</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {treatments.map((treatment) => (
            <StyledTableRow key={treatment._id}>
              <TableCell>{treatment.name}</TableCell>
              <TableCell>{treatment.duration}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleDeleteTreatment(treatment._id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {isAddTreatmentDialogOpen && (
        <AddTreatment open={isAddTreatmentDialogOpen} onClose={handleAddTreatmentClose} onTreatmentAdded={handleTreatmentAdded} />
      )}
    </Container>
  );
}

export default TreatmentsPage;
