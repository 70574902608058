import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import api from '../api';

function AISettings() {
  const { clinic } = useAuth();
  const [whatsappConfig, setWhatsappConfig] = useState({
    phoneNumberId: '',
    accessToken: '',
  });
  const [webhookURL, setWebhookURL] = useState('');

  useEffect(() => {
    if (clinic) {
      setWhatsappConfig({
        phoneNumberId: clinic.phoneNumberId || '',
        accessToken: clinic.accessToken || '',
      });
      if (clinic.webhookURL) {
        setWebhookURL(clinic.webhookURL);
      }
    }
  }, [clinic]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWhatsappConfig({
      ...whatsappConfig,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      await api.put(`/clinics/update-clinic-config/${clinic._id}`, {
        ...whatsappConfig,
      });
      alert('Configuration saved successfully');
    } catch (error) {
      console.error('Error saving the configuration:', error);
      alert('Could not save the configuration');
    }
  };

  const handleGenerateWebhook = async () => {
    try {
      const response = await api.post(`/api/clinics/${clinic._id}/generate-webhook`);
      setWebhookURL(response.data.webhookURL);
      alert('Webhook URL generated successfully');
    } catch (error) {
      console.error('Error generating the webhook URL:', error);
      alert('Could not generate the webhook URL');
    }
  };

  const handleCreateAssistant = async () => {
    try {
      await api.post(`/clinics/${clinic._id}/create-assistant`);
      alert('OpenAI Assistant created successfully');
    } catch (error) {
      console.error('Error creating the assistant:', error);
      alert('Could not create the OpenAI Assistant');
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Box para la configuración del asistente de OpenAI */}
      <Box>
        <Typography variant="h4">OpenAI Assistant Configuration</Typography>
        <Box mt={2}>
          <Button
            variant="outlined"
            color={clinic.openAIAssistantId ? 'success' : 'primary'}
            onClick={handleCreateAssistant}
            sx={{ mt: 2 }}
          >
            {clinic.openAIAssistantId ? 'Your assistant is already configured' : 'Create OpenAI Assistant'}
          </Button>
        </Box>
      </Box>

      {/* Box para la configuración de WhatsApp */}
      <Box>
        <Typography variant="h4">WhatsApp Configuration</Typography>
        <TextField
          label="WhatsApp Phone Number ID"
          name="phoneNumberId"
          value={whatsappConfig.phoneNumberId}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="WhatsApp Access Token"
          name="accessToken"
          value={whatsappConfig.accessToken}
          onChange={handleChange}
          fullWidth
          margin="normal"
          type="password"
        />
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>

        <Box mt={3}>
          <Typography variant="h6">Webhook URL</Typography>
          {webhookURL ? (
            <Typography>{webhookURL}</Typography>
          ) : (
            <Button variant="outlined" color="secondary" onClick={handleGenerateWebhook}>
              Generate Webhook URL
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AISettings;
