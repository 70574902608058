import React, { useState, useEffect, useCallback } from 'react';
import api from '../api'; // Use the configured Axios instance
import {
  Table, TableHead, TableRow, TableCell, TableBody, IconButton, Collapse, Box, Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import InfiniteScroll from 'react-infinite-scroll-component';

// Styled container for the table with sticky headers
const StyledTableContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 200px)', // Adjust the height as needed
  '& thead th': {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}));

// Function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

const ProviderPurchasesTable = ({ onPurchaseDeleted, clinicId }) => {
  // State to hold provider purchases
  const [providerPurchases, setProviderPurchases] = useState([]);
  // State to manage expanded rows for displaying product details
  const [expandedRows, setExpandedRows] = useState([]);
  // State to indicate if there are more purchases to load
  const [hasMore, setHasMore] = useState(true);
  // State to track the current page for infinite scrolling
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Fixed number of rows per page

  // Fetch provider purchases with pagination
  const fetchProviderPurchases = useCallback(async (reset = false) => {
    try {
      const response = await api.get('/providerpurchases', {
        params: {
          clinic: clinicId, // Pass clinicId as 'clinic' parameter
          page: reset ? 0 : page,
          limit: rowsPerPage
        }
      });
      if (reset) {
        setProviderPurchases(response.data.purchases); // Reset purchases list
      } else {
        setProviderPurchases(prevPurchases => [...prevPurchases, ...response.data.purchases]); // Append new purchases
      }
      if (response.data.purchases.length < rowsPerPage) {
        setHasMore(false); // No more data to load
      }
      setPage(prevPage => reset ? 1 : prevPage + 1);
    } catch (error) {
      console.error('Error fetching provider purchases:', error);
      setHasMore(false); // Stop further fetching on error
    }
  }, [page, rowsPerPage, clinicId]);

  // useEffect hook to fetch provider purchases when the component mounts or the key changes
  useEffect(() => {
    if (clinicId) {
      fetchProviderPurchases(true); // Fetch data and reset state
    }
  }, [clinicId, fetchProviderPurchases]);

  // Toggle row expansion to show/hide product details
  const handleRowClick = (rowId) => {
    const isRowCurrentlyExpanded = expandedRows.includes(rowId);
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter(id => id !== rowId) // Collapse the row
      : [...expandedRows, rowId]; // Expand the row
    setExpandedRows(newExpandedRows);
  };

  // Handle the deletion of a provider purchase
  const handleDeletePurchase = async (id) => {
    try {
      await api.delete(`/providerpurchases/${id}`, {
        params: { clinic: clinicId } // Pass clinicId as 'clinic' parameter
      });
      setProviderPurchases(providerPurchases.filter(purchase => purchase._id !== id)); // Remove deleted purchase
      onPurchaseDeleted(); // Trigger any additional actions needed after deletion
    } catch (error) {
      console.error('Error deleting purchase:', error);
    }
  };

  return (
    <StyledTableContainer component="div">
      <InfiniteScroll
        dataLength={providerPurchases.length}
        next={() => fetchProviderPurchases()} // Fetch next set of purchases
        hasMore={hasMore} // Indicates if there are more purchases to load
        loader={<Typography>Loading...</Typography>} // Loader message
        scrollableTarget="scrollableDiv"
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Provider</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providerPurchases.map((purchase) => (
              <React.Fragment key={purchase._id}>
                <TableRow hover onClick={() => handleRowClick(purchase._id)}>
                  <TableCell>{purchase.provider.name}</TableCell>
                  <TableCell>{new Date(purchase.date).toLocaleDateString()}</TableCell>
                  <TableCell>{purchase.products.reduce((acc, product) => acc + product.quantity, 0)}</TableCell>
                  <TableCell>{formatCurrency(purchase.total)}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDeletePurchase(purchase._id); }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={expandedRows.includes(purchase._id)} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Product</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Unit Price</TableCell>
                              <TableCell>Batch</TableCell>
                              <TableCell>Expiration Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {purchase.products.map((product, index) => (
                              <TableRow key={`${purchase._id}-${index}`}>
                                <TableCell>{product.product.name}</TableCell>
                                <TableCell>{product.quantity}</TableCell>
                                <TableCell>{formatCurrency(product.unitPrice)}</TableCell>
                                <TableCell>{product.batch}</TableCell>
                                <TableCell>{product.expirationDate ? new Date(product.expirationDate).toLocaleDateString() : ''}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </StyledTableContainer>
  );
};

export default ProviderPurchasesTable;
