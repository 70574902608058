// Import necessary libraries and components
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Container } from '@mui/material'; // Usamos Grid V2 y Container
import CompartmentCrud from '../components/CompartmentCrud'; // Componente para gestionar compartimentos
import EnvironmentalConditionDataGrid from '../components/EnvironmentalConditionDataGrid'; // Componente para mostrar condiciones ambientales
import api from '../api'; // Instancia configurada de Axios para solicitudes API
import { useAuth } from '../contexts/AuthContext'; // Contexto de autenticación para obtener clinic ID

const TemperatureHumidityControlPage = () => {
  // Obtenemos el ID de la clínica desde el contexto de autenticación
  const { clinic } = useAuth();
  
  // Estado para almacenar los compartimentos
  const [compartments, setCompartments] = useState([]);
  
  // Estado para controlar si se están cargando los datos
  const [loading, setLoading] = useState(false);

  // Función para obtener todos los compartimentos de la clínica
  const fetchCompartments = async () => {
    setLoading(true);
    try {
      // API call to get compartments by clinic ID
      const response = await api.get(`/compartments/clinic/${clinic._id}`);
      setCompartments(response.data); // Set the fetched compartments in state
    } catch (error) {
      console.error('Error fetching compartments:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // useEffect que se ejecuta cuando el componente se monta para obtener los compartimentos
  useEffect(() => {
    if (clinic) {
      fetchCompartments(); // Fetch compartments when clinic is available
    }
  }, [clinic]);

  return (
    <Container maxWidth="lg">
        {/* Título principal de la página */}
        <Typography variant="h4">
          Temperature and Humidity Control
        </Typography>

        {/* Contenedor principal con dos secciones usando Grid V2 */}
        <Grid container spacing={3} sx={{ height: '100%', mt: 0 }}>
          {/* Columna izquierda: CRUD de compartimentos */}
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                border: '1px solid #ddd',
                padding: 2,
                borderRadius: 4,
                overflowY: 'auto',
                height: '100%', // Asegura que el componente ocupe toda la altura disponible
              }}
            >
              <Typography variant="h6">Compartments</Typography>
              {/* Componente para gestionar compartimentos */}
              <CompartmentCrud compartments={compartments} fetchCompartments={fetchCompartments} />
            </Box>
          </Grid>

          {/* Columna derecha: Logs de temperatura y humedad */}
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                border: '1px solid #ddd',
                padding: 2,
                borderRadius: 4,
                overflowY: 'auto',
                height: '100%', // Asegura que el componente ocupe toda la altura disponible
              }}
            >
              {/* Componente para mostrar las condiciones ambientales */}
              <EnvironmentalConditionDataGrid compartments={compartments} />
            </Box>
          </Grid>
        </Grid>
    </Container>
  );
};

export default TemperatureHumidityControlPage;
