import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { Grid, Typography, Button, Container, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper, IconButton } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import AddPatient from '../components/AddPatient';
import DeleteIcon from '@mui/icons-material/Delete';

const PatientsPage = () => {
  const [patients, setPatients] = useState([]);
  const [openAddPatientDialog, setOpenAddPatientDialog] = useState(false);
  const { clinic } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const response = await api.get(`/patients?clinicId=${clinic._id}`);
        setPatients(response.data);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    };

    if (clinic) {
      fetchPatients();
    }
  }, [clinic]);

  const handleAddPatientClick = () => {
    setOpenAddPatientDialog(true);
  };

  const handleAddPatientClose = () => {
    setOpenAddPatientDialog(false);
  };

  const handlePatientAdded = async () => {
    try {
      const response = await api.get(`/patients?clinicId=${clinic._id}`);
      setPatients(response.data);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/patients/${id}`);
  };

  const handleDeletePatient = async (id) => {
    try {
      await api.delete(`/patients/${id}`);
      setPatients((prev) => prev.filter((patient) => patient._id !== id));
    } catch (error) {
      console.error('Error deleting patient:', error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {/* Título de la página */}
        <Grid item xs={12}>
          <Typography variant="h4">Patients</Typography>
        </Grid>

        {/* Botón para añadir un nuevo paciente */}
        <Grid item xs={12} sm={2}>
          <Button variant="contained" color="primary" onClick={handleAddPatientClick} fullWidth>
            Add Patient
          </Button>
        </Grid>

        {/* Tabla de pacientes */}
        <Grid item xs={12}>
          <TableContainer
            sx={{
              border: '1px solid #ddd',
              borderRadius: 4, // Bordes redondeados
              overflow: 'auto', // Para asegurarse de que el contenido respete las esquinas redondeadas
            }}
          >
            <Table aria-label="patients table">
              <TableHead>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patients.map((patient) => (
                  <TableRow
                    key={patient._id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleRowClick(patient._id)}
                  >
                    <TableCell>{patient.firstName}</TableCell>
                    <TableCell>{patient.lastName}</TableCell>
                    <TableCell>{patient.email}</TableCell>
                    <TableCell>{patient.phone}</TableCell>
                    <TableCell>{patient.address}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevenir que el click en el botón redirija
                          handleDeletePatient(patient._id);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* Diálogo para añadir un nuevo paciente */}
      {openAddPatientDialog && (
        <AddPatient onClose={handleAddPatientClose} onPatientAdded={handlePatientAdded} />
      )}
    </Container>
  );
};

export default PatientsPage;
