import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box } from '@mui/material';
import api from '../api'; // Importar la instancia configurada de Axios

const AddTreatment = ({ open, onClose, onTreatmentAdded }) => {
  const [formData, setFormData] = useState({
    name: '',
    duration: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post('/treatments', formData);
      onTreatmentAdded(response.data);
      onClose();
    } catch (error) {
      console.error('Error adding treatment:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>New Treatment</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            name="name"
            label="Treatment Name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            name="duration"
            label="Duration in minutes"
            type="number"
            value={formData.duration}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTreatment;
